export const getSpCount = (spCount) => {
    return spCount !== null ? (spCount === 0 ? 0.25 : spCount) : 0;
}

export const getSupportSpCount = (category, spCount=null) => {
    switch (category) {
        case 31:
            return 1 / 8;
        case 8467:
            return getSpCount(spCount);
        default:
            return spCount ?? 0.33
    }
}