import React, { useState } from 'react';
import {
    TableCell,
    TableRow,
} from '@mui/material';
import '../styles/month-report.scss';
import { getSupportSpCount } from '../shared/utils';

export function SupportTaskRow(props) {
    const {
        task,
        setCategorySelectValue,
        setResponsibleSelectValue,
        setStatusSelectValue
    } = props;
    const [background, setBackground] = useState(task.status.background_color);

    return (
        <TableRow
            key={task.id}
            style={{ background: background }}
            onMouseEnter={() => { setBackground(task.status.background_color !== 'null' ? task.status.background_color + '4d' : '#0000000a') }}
            onMouseLeave={() => setBackground(task.status.background_color !== 'null' ? task.status.background_color : null)}
        >
            <TableCell width={50}>
                <a href={`https://bitrix.borauto.ru/company/personal/user/1835/tasks/task/view/${task.task_id}/`} target="_blank" rel="noreferrer">{task.task_id}</a>
            </TableCell>
            <TableCell
                width={10}
                align="center"
                onClick={() => setCategorySelectValue(task.category_id)}
                className='table-row__use-filter'
            >
                {task.category_name}
            </TableCell>
            <TableCell width={'35%'} align="left">{task.title}</TableCell>
            <TableCell
                width={150}
                onClick={() => setResponsibleSelectValue(task.responsible_id)}
                className='table-row__use-filter'
            >
                {task.responsible_name}
            </TableCell>
            <TableCell width={10} align="center">{getSupportSpCount(task.category_id, task.sp_count)}</TableCell>
            <TableCell width={110} align="center">{task.formated_deadline}</TableCell>
            <TableCell width={10} align="center">{task.formated_closed_date}</TableCell>
            <TableCell
                width={140}
                align="center"
                onClick={() => setStatusSelectValue(task.status.id)}
                className='table-row__use-filter'
            >
                {task.status.name}
            </TableCell>
        </TableRow>
    )
}