import React, { useState } from 'react';
import { useStore } from 'effector-react';
import { getSupportTasks, postSupportTasks, getAllMonthSprints } from '../components/api';
import { $supportTasks, $users, $authUserData, setSupportTasks } from '../store/cards';
import { SupportTaskRow } from '../components';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Select,
    MenuItem,
    IconButton
} from '@mui/material';
import {
    Close,
    ArrowLeft,
    ArrowRight,
} from '@mui/icons-material';
import { getSpCount } from '../shared/utils';
import '../styles/month-report.scss';
import '../styles/table-combobox.scss';

export function SupportMonthReportPage() {
    const users = useStore($users);
    const authUserData = useStore($authUserData);
    const [isLoaded, setIsLoaded] = useState(false);
    const supportTasks = useStore($supportTasks);
    const [sprints, setSprints] = useState([]);
    const [responsibleSelectValue, setResponsibleSelectValue] = useState(0);
    const [selectedSprint, setSelectedSprint] = useState(`${new Date().getMonth() + 1};${new Date().getFullYear()}`);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [categorySelectValue, setCategorySelectValue] = useState(-1);
    const [statusSelectValue, setStatusSelectValue] = useState(0);

    React.useEffect(() => {
        document.title = "TaskMan - Задачи поддержки";
    });

    const getSprintData = () => {
        const sprintData = selectedSprint.target ? selectedSprint.target.value : selectedSprint;
        const [month, year] = sprintData.split(';');
        return {
            month: month,
            year: year
        }
    }

    React.useEffect(() => {
        getAllMonthSprints().then(data => setSprints(data));
        if (sprints.length) {
            setSelectedSprint(`${sprints[0].month};${sprints[0].year}`);
        }
    }, [sprints.length]);

    React.useEffect(() => {
        const { month, year } = getSprintData();

        getSupportTasks(year, month).then(monthSupportReport => {
            setSupportTasks(monthSupportReport);
            setIsLoaded(true);
        });
    }, [isLoaded, selectedSprint]);

    const changeSelectResponsible = (event) => {
        setResponsibleSelectValue(event.target.value);
    }

    const changeSelectCategory = (event) => {
        setCategorySelectValue(event.target.value);
    }

    const changeSelectStatus = (event) => {
        setStatusSelectValue(event.target.value);
    }

    const getSupportSPCount = () => {
        const supportSP = filteredTasks.filter(task => task.category_id === 30)
            .reduce((partialSum, a) => partialSum + (a.sp_count ?? 1.0 / 3.0), 0)
            .toFixed(2); //SP техподдержки
        const rulesSP = (filteredTasks.filter(task => task.category_id === 31).length / 8).toFixed(2); //SP Прав
        const developmentTsTasks = filteredTasks.filter(task => task.category_id === 8467);
        const developmentTsSP = developmentTsTasks.length
            ? (filteredTasks.filter(task => task.category_id === 8467)
                .map(task => getSpCount(task.sp_count))
                .reduce((a, b) => (a ?? 0) + (b ?? 0), 0)).toFixed(2)
            : 0; //SP проработки ТЗ
        const tasksWithoutCategoryCount = filteredTasks.filter(task => task.deadline === null).length;
        const tasksCount = filteredTasks.length;
        const overdueTasksCount = filteredTasks.filter(task => task.status.id === -1).length;
        const completedOnTime = Math.round((tasksCount - tasksWithoutCategoryCount - overdueTasksCount) / tasksCount * 100);

        return [
            `${(Number(supportSP) + Number(rulesSP) + Number(developmentTsSP)).toFixed(2)} SP (${tasksCount})`,
            `${completedOnTime} %`,
            filteredTasks.filter(task => task.status.id === 4).length,
            tasksWithoutCategoryCount,
            overdueTasksCount,
            `${supportSP} SP (${filteredTasks.filter(task => task.category_id === 30).length})`,
            `${rulesSP} SP (${filteredTasks.filter(task => task.category_id === 31).length})`,
            `${developmentTsSP} SP (${filteredTasks.filter(task => task.category_id === 8467).length})`
        ];
    }

    const loadSupportTasksClick = () => {
        setIsLoadingData(true);
        const { month, year } = getSprintData();
        postSupportTasks(year, month).then(() => {
            getSupportTasks(year, month).then(monthReport => {
                setSupportTasks(monthReport);
                setIsLoadingData(false);
            });
        });

    }

    const formattedData = (month, year) => {
        var options = {
            year: 'numeric',
            month: 'long',
            timezone: 'UTC'
        };
        const date = new Date(`${year}-${month}-01`);
        return `${date.toLocaleString("ru", options)}`
    };

    const changeSelectedSprint = (event) => {
        setSelectedSprint(event.target.value);
    }

    const filteredTasks = supportTasks.filter(task => {
        const hasResponsible = Number(responsibleSelectValue) === 0 ? true : Number(task.responsible_id) === Number(responsibleSelectValue);
        const hasStatus = Number(statusSelectValue) === 0 ? true : Number(task.status.id) === Number(statusSelectValue);
        const hasCategory = Number(categorySelectValue) === -1 ? true : Number(task.category_id) === Number(categorySelectValue);
        return hasResponsible && hasStatus && hasCategory;
    });

    const previousMonth = () => {
        const { month, year } = getSprintData();
        const date = new Date(`${year}-${month}-01`);
        date.setMonth(date.getMonth() - 1);
        setSelectedSprint(`${date.getMonth() + 1};${date.getFullYear()}`)
    }

    const nextMonth = () => {
        const { month, year } = getSprintData();
        const date = new Date(`${year}-${month}-01`);
        date.setMonth(date.getMonth() + 1);
        setSelectedSprint(`${date.getMonth() + 1};${date.getFullYear()}`)
    }

    const getSprintMonthYearToSelect = (sprint) => {
        return sprint ? `${sprint.month};${sprint.year}` : `${new Date().getMonth() + 1};${new Date().getFullYear()}`;
    }

    return (
        <div>
            <div className='month-report__month-controls'>
                <IconButton
                    style={{ padding: 0 }}
                    onClick={() => previousMonth()}
                    disabled={getSprintMonthYearToSelect(sprints[sprints.length - 1]) === selectedSprint}
                >
                    <ArrowLeft />
                </IconButton>
                <Select className='month-report__sprints-combobox' onChange={changeSelectedSprint} value={selectedSprint}>
                    {sprints.map((sprint) => {
                        return (<MenuItem value={getSprintMonthYearToSelect(sprint)} key={sprint.month + sprint.year}>{`${formattedData(sprint.month, sprint.year)}`}</MenuItem>)
                    })}
                </Select>
                <IconButton
                    style={{ padding: 0 }}
                    onClick={() => nextMonth()}
                    disabled={getSprintMonthYearToSelect(sprints[0]) === selectedSprint}
                >
                    <ArrowRight />
                </IconButton>
                {authUserData && authUserData.is_head_of_department ? <Button variant="contained" onClick={loadSupportTasksClick} disabled={isLoadingData} style={{ marginLeft: "10px" }}>Загрузить</Button> : null}
            </div>
            <h3>Задачи</h3>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell width={10} align="center"><b>Всего</b></TableCell>
                            <TableCell width={10} align="center"><b>Завершены в срок</b></TableCell>
                            <TableCell width={10} align="center"><b>Ждут контроля</b></TableCell>
                            <TableCell width={10} align="center"><b>Без крайнего срока</b></TableCell>
                            <TableCell width={10} align="center"><b>Просрочены</b></TableCell>
                            <TableCell width={10} align="center"><b>Техподдержка</b></TableCell>
                            <TableCell width={10} align="center"><b>Права</b></TableCell>
                            <TableCell width={10} align="center"><b>Проработка ТЗ</b></TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell width={10} align="center"><b>{getSupportSPCount()[0]}</b></TableCell>
                            <TableCell width={10} align="center"><b>{getSupportSPCount()[1]}</b></TableCell>
                            <TableCell width={10} align="center"><b>{getSupportSPCount()[2]}</b></TableCell>
                            <TableCell width={10} align="center"><b>{getSupportSPCount()[3]}</b></TableCell>
                            <TableCell width={10} align="center"><b>{getSupportSPCount()[4]}</b></TableCell>
                            <TableCell width={10} align="center"><b>{getSupportSPCount()[5]}</b></TableCell>
                            <TableCell width={10} align="center"><b>{getSupportSPCount()[6]}</b></TableCell>
                            <TableCell width={10} align="center"><b>{getSupportSPCount()[7]}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer component={Paper} sx={{ maxHeight: "calc(100vh - 200px)" }}>
                <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="simple sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell width={10}><b>№</b></TableCell>
                            <TableCell width={10} align="center">
                                <div>
                                    <Select className='table-combobox__header' value={categorySelectValue} onChange={changeSelectCategory}>
                                        <MenuItem value={-1} key={-1}>Категория</MenuItem>
                                        <MenuItem value={0} key={0}>Без категории</MenuItem>
                                        <MenuItem value={30} key={30}>Техподдержка 1С</MenuItem>
                                        <MenuItem value={31} key={31}>Права 1С</MenuItem>
                                        <MenuItem value={8467} key={8467}>Проработка ТЗ</MenuItem>
                                    </Select>
                                    {
                                        categorySelectValue !== -1
                                            ? <IconButton onClick={() => setCategorySelectValue(-1)}>
                                                <Close style={{ fontSize: 16 }} />
                                            </IconButton>
                                            : null
                                    }
                                </div>
                            </TableCell>
                            <TableCell width={'35%'} align="left"><b>Название</b></TableCell>
                            <TableCell width={150}>
                                <div className='table-header__column'>
                                    <Select className='table-combobox__header' value={responsibleSelectValue} onChange={changeSelectResponsible}>
                                        <MenuItem value={0} key={0}>Исполнитель</MenuItem>
                                        {users.filter(user => (
                                            user.active && supportTasks.filter(task => task.responsible_id === Number(user.bitrix_user_id)).length) && user.id !== -1
                                        ).map((user) => {
                                            return (<MenuItem value={user.bitrix_user_id} key={user.id}>{`${user.surname} ${user.name}`}</MenuItem>)
                                        })}
                                    </Select>
                                    {
                                        responsibleSelectValue !== 0
                                            ? <IconButton onClick={() => setResponsibleSelectValue(0)}>
                                                <Close style={{ fontSize: 16 }} />
                                            </IconButton>
                                            : null
                                    }
                                </div>
                            </TableCell>
                            <TableCell width={10} align="center"><b>SP</b></TableCell>
                            <TableCell width={110} align="center"><b>Крайний срок</b></TableCell>
                            <TableCell width={110} align="center"><b>Дата завершения</b></TableCell>
                            <TableCell width={140} align="center">
                                <div>
                                    <Select className='table-combobox__header' value={statusSelectValue} onChange={changeSelectStatus}>
                                        <MenuItem value={0} key={0}>Статус</MenuItem>
                                        <MenuItem value={-1} key={-1}>Просрочена</MenuItem>
                                        <MenuItem value={4} key={30}>Ждет контроля</MenuItem>
                                        <MenuItem value={5} key={31}>Завершена</MenuItem>
                                    </Select>
                                    {
                                        statusSelectValue !== 0
                                            ? <IconButton onClick={() => setStatusSelectValue(0)}>
                                                <Close style={{ fontSize: 16 }} />
                                            </IconButton>
                                            : null
                                    }
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredTasks.map((task) => {
                            return (
                                <SupportTaskRow
                                    key={task.id}
                                    task={task}
                                    setCategorySelectValue={setCategorySelectValue}
                                    setResponsibleSelectValue={setResponsibleSelectValue}
                                    setStatusSelectValue={setStatusSelectValue}
                                />
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}